import {ComponentObserver} from './ComponentObserver';

/**
 * Dynamically load JS components
 */
export class ComponentLoader {
    /**
     * @type {WeakMap<Element, object>}
     */
    components = new WeakMap();

    /**
     * @type {Object}
     */
    options = {
        componentsDirectory: 'components',
        componentSelector: '[data-component]',
        componentDatasetName: 'component',
        observerOptions: {},
    };

    /**
     * @type {ComponentObserver}
     */
    observer;

    /**
     * @param {Object} options
     */
    constructor(options = {}) {
        this.options = {...this.options, ...options};
        this.observer = new ComponentObserver(this, this.options.observerOptions);
    }

    loadComponentsWithin(element, observe = true) {
        this.findElementsWithComponentDefinition(element, false)
            .forEach(element => this.loadComponentForElement(element));
        if (observe) {
            this.observer.createObserversWithin(element);
        }
    }

    /**
     * @param {HTMLElement} element
     * @param {Boolean} observe
     */
    loadComponents(element, observe = true) {
        this.findElementsWithComponentDefinition(element, true)
            .forEach(element => this.loadComponentForElement(element));
        if (observe) {
            this.observer.createObserversWithin(element);
        }
    }

    /**
     * @param {HTMLElement} element
     */
    destroyComponentsWithin(element) {
        this.findElementsWithComponentDefinition(element, false)
            .forEach(element => this.destroyComponentForElement(element));
    }

    /**
     * @param {HTMLElement} element
     */
    destroyComponents(element) {
        this.findElementsWithComponentDefinition(element, true)
            .forEach(element => this.destroyComponentForElement(element));
    }

    /**
     * Load JS component dynamically
     *
     * @param {HTMLElement} element
     * @param {String|null} preferredComponentName
     *
     * @return {Promise}
     */
    async loadComponentForElement(element, preferredComponentName = null) {
        if (this.components.has(element)) {
            throw new Error('Element already has a component instance.');
        }

        let componentName = preferredComponentName === null ?
            this.getComponentDefinition(element) :
            preferredComponentName;

        const component = await this.importComponent(componentName);
        const instance = component.default(element);
        this.components.set(element, instance);
        return instance;
    }

    destroyComponentForElement(element) {
        if (!this.components.has(element)) {
            return;
        }

        const instance = this.components.get(element);
        this.components.delete(element);
        if (typeof instance.destroy === 'function') {
            // Schedule the destruction of the component instance in the next event loop.
            setTimeout(() => instance.destroy(), 0);
        }
    }

    /**
     * Dynamically import component
     * Note: Import path must be hardcoded for now, doesn't work with a variable
     *
     * @param {String} name
     * @return {Promise}
     */
    importComponent(name) {
        return import(`./../../components/${name}.js`);
    }

    findElementsWithComponentDefinition(element, includeSelf) {
        const elements = [...element.querySelectorAll(this.options.componentSelector)];
        if (includeSelf && this.getComponentDefinition(element) !== null) {
            elements.unshift(element);
        }
        return elements;
    }

    /**
     * @param {HTMLElement} element
     * @return {String|null}
     */
    getComponentDefinition(element) {
        return this.options.componentDatasetName in element.dataset ?
            element.dataset[this.options.componentDatasetName] :
            null;
    }
}
